<template>
  <div class="edit-participant-record-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'ParticipantRecord' }"
        >活動報名資料</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'EditParticipantRecord' }"
        >報名紀錄</el-breadcrumb-item
      >
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="participantRecordForm"
        :model="participant_record_form"
        :rules="participant_record_rules"
        label-position="top"
      >
        <el-form-item label="活動名稱">
          <el-input :value="activity.title_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="名稱">
          <el-input :value="participant.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="信箱">
          <el-input :value="participant.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="出席" prop="attend">
          <el-radio
            v-model="participant_record_form.attend"
            :label="true"
            disabled
            >出席</el-radio
          >
          <el-radio
            v-model="participant_record_form.attend"
            :label="false"
            disabled
            >未出席</el-radio
          >
        </el-form-item>
        <el-form-item label="問題回答1" prop="answer_1">
          <el-input
            type="textarea"
            v-model="participant_record_form.answer_1"
            maxlength="256"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="問題回答2" prop="answer_2">
          <el-input
            type="textarea"
            v-model="participant_record_form.answer_2"
            maxlength="256"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="問題回答3" prop="answer_3">
          <el-input
            type="textarea"
            v-model="participant_record_form.answer_3"
            maxlength="256"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getParticipantRecord } from "@/api/participant-record";

export default {
  name: "DetailParticipantRecord",
  data() {
    return {
      participant_record_form: {
        attend: false,
        answer_1: "",
        answer_2: "",
        answer_3: "",
      },
      participant_record_rules: {},
      participant: {},
      activity: {},
    };
  },
  computed: {
    participantRecordId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetParticipantRecord();
  },
  methods: {
    async handleGetParticipantRecord() {
      try {
        const { participant, activity, ...rest } = await getParticipantRecord(
          this.participantRecordId
        );

        this.participant = participant;
        this.activity = activity;

        Object.keys(this.participant_record_form).forEach((key) => {
          if (rest[key]) {
            this.participant_record_form[key] = rest[key];
          }
        });
      } catch (error) {
        await this.$alert("輸入資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    async handleRedirect() {
      await this.$router.push({ name: "ParticipantRecord" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
